import React, { useMemo } from "react"
import Select from 'react-select'
import { Link, navigate } from "gatsby"
import { getItem, isLoggedIn, logout } from "../../services/auth"
import { CreditCardIcon, KeyIcon, UserCircleIcon, UserGroupIcon, ViewGridAddIcon } from '@heroicons/react/outline'
import { GetRequestAuth, PostRequestAuth } from "../../services/api/backend";
import AlertSuccess from "../../components/alert/success";
import countryList from 'react-select-country-list'

const navigation = [
  { name: 'Account', href: '/member/account', icon: UserCircleIcon, current: true },
  { name: 'Password', href: '/member/password', icon: KeyIcon, current: false },
  { name: 'Plan & Billing', href: '#', icon: CreditCardIcon, current: false },
  { name: 'News Timeline Setting', href: '#', icon: UserGroupIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


class Account extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      country_list: countryList().setLabel('TW', 'Taiwan').getData(),
      username: "",
      about: "",
      name: "",
      last_name: "",
      email: "",
      country: "",
      street_address: "",
      city: "",
      state: "",
      zip: "",
      token: getItem("token"),
      success_save: {
        main_info: false,
        personal_info: false
      }
    };
    this.changeHandlerCountry.bind(this);
    this.onInputchange = this.onInputchange.bind(this);
    this.onSaveMainInfo.bind(this);
    this.onSavePersonalInfo.bind(this);
  }

  componentDidMount() {
    
    console.log("gogoblock backend call");
    GetRequestAuth("user", this.state.token)
      .then(res => {
        console.log("result get user", res);
        this.setState({
          username: res.data.username,
          about: res.data.about,
          name: res.data.name,
          last_name: res.data.last_name,
          email: res.data.email,
          country: res.data.country,
          street_address: res.data.street_address,
          city: res.data.city,
          state: res.data.state,
          zip: res.data.zip
      });
      }).catch((err) => {
    });
  }

  changeHandlerCountry = (e) => {
    console.log("change country : ", e);
    this.setState({
      country: e.label
    });
  };

  onInputchange(event) {
    console.log("event : ", event);
    this.setState({
      [event.target.name]: event.target.value

    });
  }

  onSaveMainInfo = (e) => {
    e.preventDefault();
    const data = {
      username: e.target.username.value,
      about: e.target.about.value
    }
    PostRequestAuth("user-main-info",this.state.token, data)
      .then(res => {
        if(res.data.success){
          this.setState(state => ({
            success_save: {
              main_info: true
            }
          }));
        }
      }).catch((err) => {

    });
  };

  onSavePersonalInfo = (e) => {
    e.preventDefault();
    console.log(e);
    const data = {
      name: e.target.name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      country: this.state.country,
      street_address: e.target.street_address.value,
      city: e.target.city.value,
      state: e.target.state.value,
      zip: e.target.zip.value,
    }
    PostRequestAuth("user-personal-info",this.state.token, data)
      .then(res => {
        if(res.data.success){
          this.setState(state => ({
            success_save: {
              personal_info: true
            }
          }));
        }
      }).catch((err) => {

    });
  };

  render() {
  let greetingMessage = "";
  let name = "";
  let email = "email";
  if (isLoggedIn()) {
    greetingMessage = `Hello ${getItem("user").name}`
    name = getItem("user").name;
    email = getItem("user").email;
  } else {
    greetingMessage = "You are not logged in"
  }
  return (

    <>
      <div className='container border-b-2 border-primary mb-6'>
        <h1 className='p-6 text-3xl text-center uppercase text-skin-primary'>Your Culb Mella Profile</h1>
      </div>
      <div className="container">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                      : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                    'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? 'text-indigo-500 group-hover:text-indigo-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                </Link>
              ))}
            </nav>
          </aside>

          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <form onSubmit={e => this.onSaveMainInfo(e)} method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      This information will be displayed publicly so be careful what you share.
                    </p>
                    {this.state.success_save.main_info &&
                    <AlertSuccess title="Succes : Your info has been updated"/>
                    }
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                        Username
                      </label>
                      <div className="mt-1 rounded-md shadow-sm flex">
                        <input
                          type="text"
                          name="username"
                          id="username"
                          onChange={this.onInputchange}
                          value={this.state.username == null ? '' : this.state.username}
                          autoComplete="username"
                          className="p-2 border focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>

                    <div className="col-span-3">
                      <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                        About
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          onChange={this.onInputchange}
                          value={this.state.about == null ? '' : this.state.about}
                          className="p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        Brief description for your profile. URLs are hyperlinked.
                      </p>
                    </div>

                    {/* <div className="col-span-3">
                      <label className="block text-sm font-medium text-gray-700">Your handsome picture</label>
                      <div className="mt-1 flex items-center">
                        <span className="inline-block bg-gray-100 rounded-full overflow-hidden h-12 w-12">
                          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                        <button
                          type="button"
                          className="ml-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Change
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="bg-skin-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>

            <form onSubmit={e => this.onSavePersonalInfo(e)} method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Personal Information</h3>
                    <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can recieve mail.</p>
                    {this.state.success_save.personal_info &&
                    <AlertSuccess title="Succes : Your infos has been updated"/>
                    }
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        First name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="first-name"
                        onChange={this.onInputchange}
                        value={this.state.name == null ? '' : this.state.name}
                        autoComplete="given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        id="last-name"
                        onChange={this.onInputchange}
                        value={this.state.last_name == null ? '' : this.state.last_name}
                        autoComplete="family-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="email-address"
                        onChange={this.onInputchange}
                        value={this.state.email == null ? '' : this.state.email}
                        autoComplete="email"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Country
                      </label>

                      <Select 
                      options={this.state.country_list} 
                      defaultValue={this.state.country}
                      onChange={e => this.changeHandlerCountry(e)}
                      />

                      {/* <select
                        id="country"
                        name="country"
                        value={this.state.form.country}
                        autoComplete="country-name"
                        className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>United States</option>
                        <option>Canada</option>
                        <option>Mexico</option>
                      </select> */}
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                        Street address
                      </label>
                      <input
                        type="text"
                        name="street_address"
                        id="street_address"
                        onChange={this.onInputchange}
                        value={this.state.street_address == null ? '' : this.state.street_address}
                        autoComplete="street-address"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        onChange={this.onInputchange}
                        value={this.state.city == null ? '' : this.state.city}
                        autoComplete="address-level2"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                        State / Province
                      </label>
                      <input
                        type="text"
                        name="state"
                        id="state"
                        onChange={this.onInputchange}
                        value={this.state.state == null ? '' : this.state.state}
                        autoComplete="address-level1"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                        ZIP / Postal code
                      </label>
                      <input
                        type="text"
                        name="zip"
                        id="zip"
                        onChange={this.onInputchange}
                        value={this.state.zip == null ? '' : this.state.zip}
                        autoComplete="postal-code"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="bg-skin-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>

            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Notifications</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Control what type of notification you want to receive
                    </p>
                  </div>

                  <fieldset>
                    <legend className="text-base font-medium text-gray-900">By Email</legend>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-start">
                        <div className="h-5 flex items-center">
                          <input
                            id="priceAlert"
                            name="priceAlert"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="priceAlert" className="font-medium text-gray-700">
                            Price alert
                          </label>
                          <p className="text-gray-500">Get notified when the price of an asset reached your alert.</p>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-start">
                          <div className="h-5 flex items-center">
                            <input
                              id="newsAlert"
                              name="newsAlert"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="newsAlert" className="font-medium text-gray-700">
                              News alert
                            </label>
                            <p className="text-gray-500">Get notified when any of the keywords you set has been reported in the media.</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-start">
                          <div className="h-5 flex items-center">
                            <input
                              id="offers"
                              name="offers"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Information on your account
                            </label>
                            <p className="text-gray-500">Get notified when there is an update to your account.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="mt-6">
                    <legend className="text-base font-medium text-gray-900">Push Notifications</legend>
                    <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="push-everything"
                          name="push-notifications"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor="push-everything" className="ml-3">
                          <span className="block text-sm font-medium text-gray-700">Everything</span>
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push-email"
                          name="push-notifications"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor="push-email" className="ml-3">
                          <span className="block text-sm font-medium text-gray-700">Same as email</span>
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push-nothing"
                          name="push-notifications"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor="push-nothing" className="ml-3">
                          <span className="block text-sm font-medium text-gray-700">No push notifications</span>
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="bg-skin-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* end  */}
      <div className="container mt-10">
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
            borderBottom: "1px solid #d1c1e0",
          }}
        >

        <span>{greetingMessage}</span>
        <div>Name : {name}</div>
        <div>email : {email}</div>
          <nav>
            <Link to="/">Home</Link>
            {` `}
            <Link to="/app/profile">Profile</Link>
            {` `}
            {isLoggedIn() ? (
              <a
                href="/"
                onClick={event => {
                  event.preventDefault()
                  logout(() => navigate(`/app/login`))
                }}
              >
                Logout
              </a>
            ) : null}
          </nav>
        </div>
      </div>
    </>
    );
  }
}
export default Account;